import React from "react"
import { graphql } from "gatsby"
import BrandPage from "../social-supermarket/pages/brand/BrandPage"
import { mapBrand } from "../gatsby/transformers/brandTransformer"
import { filterByVisibility } from "../social-supermarket/util/productUtil"

const BrandTemplate = ({ data, pageContext: { products } }) => {
  const brand = mapBrand(data.wpBrand)
  return <BrandPage brand={brand} products={filterByVisibility(products, "Brand Page")} />
}

export default BrandTemplate

export const query = graphql`
  query ($id: String!) {
    wpBrand(id: { eq: $id }) {
      id
      name
      description
      slug
      banner {
        ...UrlImageFragment
      }
      image {
        ...UrlImageFragment
      }
      custom {
        impactOneLine
        acceptsGiftNotes
        deliveryBusinessDays
        founderNames
        meetTheFounder
        impactIcons
        shippingInfo
        shortDescription
        videoEmbed
        founderTitle
        websiteUrl
        brandLogo {
          ...UrlImageFragment
        }
        founderProfilePhoto {
          ...UrlImageFragment
        }
      }
    }
  }
`
